import React from "react";
import { graphql } from "gatsby";

import Album from "components/Album";
import { Grid, GridItem } from "components/Grid/Grid";
import Layout, { Footer } from "components/Layout";
import Number from "components/Number";


function Index(props) {
  const { data, pageContext } = props;
  const albums = data.allAlbumsYaml.nodes;

  return (
    <Layout>

      <h1>Chosen by <span style={{ textTransform: `capitalize` }}>{pageContext.host}</span></h1>
      <p className="stat">{albums.length} album{albums.length > 1 && "s"}</p>

      <Grid medium>
        {albums.map(album => (
          <GridItem key={album.number} id={album.number}>
            <Number data={album} />
            <Album data={album} cover={album.cover} />
          </GridItem>
        ))}
      </Grid>

      <Footer />
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`
  query AlbumByHost(
    $host: String!
  ) {
    allAlbumsYaml(filter: {host: {eq: $host}}) {
      nodes {
        artist
        cover {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 800
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        host
        number
        played_on
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }) => <title>Hosted by {data.allAlbumsYaml.nodes[0].host} - {data.site.siteMetadata.title}</title>;
